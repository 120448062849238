import styled from 'styled-components'
import { largeBreakpoint, mediumBreakpoint } from '../../config/css-constants'

export const Section = styled.section`
  padding-bottom: 50px;
  @media all and (max-width: ${mediumBreakpoint}) {
    padding-top: 80px;
  }
`
export const InterestedSection = styled.section`
  text-align: center;
  margin-top: 100px;
`

export const InterestedHeader = styled.div`
  letter-spacing: 1px;
  padding-bottom: 5px;
  @media all and (min-width: ${largeBreakpoint}) {
    text-align: center;
    font-size: 1.2rem;
    letter-spacing: 1.2px;
  }
`

export const InterestedHeaderQuestion = styled.span`
  display: block;
  font-size: 1.1rem;
  @media all and (min-width: ${largeBreakpoint}) {
    display: inline;
  }
`

export const InterestedText = styled.p`
  font-size: 1.1rem;
`

export const InterestedHeaderAnswer = styled.p`
  padding-left: 6px;
`
