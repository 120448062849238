import React from 'react'
import { Title, LookigFor } from './styles'

const CareerPageHeader = () => (
  <>
    <Title>
      We are continually looking for exceptional individuals, who are ready to
      make a difference and join our growing team. Our team is usually one of
      the first ones to play around with cutting-edge technology that leads to
      great solutions. Together with our professional approach tailored to our
      clients’ needs, we help them to grow by leveraging companies' business
      potential. If you are a person, who is curious about the latest IT trends,
      not afraid of new challenges, and determined to make a positive impact,
      contact us.
    </Title>
    <LookigFor>
      Currently, we are recruiting for the following positions:
    </LookigFor>
  </>
)

export default CareerPageHeader
