import styled from 'styled-components'
import { largeBreakpoint } from '../../../config/css-constants'
import { Link } from 'gatsby'

export const Wrapper = styled.div``

export const EmployeesSection = styled.section`
  @media all and (min-width: ${largeBreakpoint}) {
    text-align: center;
  }
`

export const EployeesList = styled.ul`
  @media all and (min-width: ${largeBreakpoint}) {
    padding-bottom: 50px;
    margin: 0 auto;
    text-align: left;
  }
`

export const EployeesSingleList = styled.li`
  padding-top: 15px;
  padding-bottom: 5px;
  transition: all 0.5s ease;
`

export const EployeeLink = styled(Link)`
  display: inline-block;
  color: #000;
  font-weight: 300;
  border-bottom: 2px solid transparent;
  transition: 0.4s ease all;

  &:after {
    display: block;
    content: '';
    border-bottom: solid 1px #e54541;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    margin-bottom: -1px;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: 0% 100%;
  }
`
