import styled from 'styled-components'

import { mediumBreakpoint } from '../../../config/css-constants'

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  padding-bottom: 100px;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding-top: 50px;
  }
`

export const LookigFor = styled.p`
  padding-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 400;
  font-weight: bold;
`
