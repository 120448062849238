import styled from 'styled-components'
import { Link } from 'gatsby'
import React from 'react'
import { mediumBreakpoint } from '../../config/css-constants'

export const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: ${(props) => props.paddingTop || '0'};
  padding-bottom: ${(props) => props.paddingBottom || '0'};
`

export const StyledButton = styled((props) => <Link {...props} />)`
  padding: 10px 30px;
  border-radius: 15px;
  display: inline-block;
  margin-bottom: 30px;
  transition: all 0.4s ease;
  min-width: 150px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1rem;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-top: 30px;
  }
`
export const ButtonLink = styled(StyledButton)`
  background-color: #357e8d;
  border: 2px solid transparent;
  color: #fff;
  &:hover {
    color: #357e8d;
    border: 2px solid #357e8d;
    background-color: unset;
  }
`

export const YellowButton = styled(StyledButton)`
  background-color: #ffc34a;
  border: 2px solid transparent;
  color: #397e8d;

  &:hover {
    color: #ffc34a;
    border: 2px solid #ffc34a;
    background-color: unset;
  }
`
