import styled from 'styled-components'
import {
  fullhd,
  largeBreakpoint,
  mediumBreakpoint,
} from '../../config/css-constants'

export const Wrapper = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 20px;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding-top: 50px;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    max-width: 85%;
    margin: 0 auto;
  }
  @media screen and (min-width: ${fullhd}) {
    margin-left: 64px;
    margin-right: 64px;
    padding-left: 7rem;
    padding-right: 3rem;
    max-width: unset;
  }
`
