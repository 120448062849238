import React from 'react'
import { graphql } from 'gatsby'

import ContactUsLink from '../../components/ReusableComponents/ContactUsLink'
import CareerPageHeader from '../../components/CustomCareer/CareerPageHeader/CareerPageHeader'
import CareerEmployees from '../../components/CustomCareer/CareerEmployees/CareerEmployees'

import { Wrapper } from '../../components/CustomCareer/styles'

const CareerLayout = ({ data }) => {
  const { edges } = data.allMarkdownRemark

  return (
    <Wrapper>
      <CareerPageHeader />
      <CareerEmployees employees={edges} />
      <ContactUsLink />
    </Wrapper>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fields: { collection: { eq: "career" } } }) {
      edges {
        node {
          fields {
            collection
          }
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`

export default CareerLayout
