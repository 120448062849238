import React from 'react'
import {
  Wrapper,
  EmployeesSection,
  EployeesList,
  EployeesSingleList,
  EployeeLink,
} from './styles'
import { StaticQuery, graphql } from 'gatsby'

const CareerEmployees = () => {
  return (
    <Wrapper>
      <EmployeesSection>
        <EployeesList>
          <StaticQuery
            query={graphql`
              query {
                allMarkdownRemark(
                  filter: { fields: { collection: { eq: "career" } } }
                  sort: { fields: frontmatter___date, order: DESC }
                ) {
                  edges {
                    node {
                      fields {
                        collection
                      }
                      frontmatter {
                        title
                        path
                      }
                    }
                  }
                }
              }
            `}
            render={(data) => {
              const { edges } = data.allMarkdownRemark
              const workersList = edges.map((employee) => {
                const title = employee.node.frontmatter.title
                const path = employee.node.frontmatter.path
                return (
                  <EployeesSingleList key={title}>
                    <EployeeLink to={`/career${path}`}>
                      {title.includes('NEW') ? <strong>{title}</strong> : title}
                    </EployeeLink>
                  </EployeesSingleList>
                )
              })
              return <>{workersList}</>
            }}
          />
        </EployeesList>
      </EmployeesSection>
    </Wrapper>
  )
}

export default CareerEmployees
