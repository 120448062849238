import React from 'react'
import {
  Section,
  InterestedHeaderAnswer,
  InterestedHeader,
  InterestedHeaderQuestion,
  InterestedSection,
  InterestedText,
} from './ContactUsLinkStyles'
import { ButtonLink } from '../UI/button'

const ContactUsLink = () => (
  <Section>
    <InterestedHeaderQuestion>Interested?</InterestedHeaderQuestion>
    <InterestedText>
      Send us your CV at <b>hello@deployed.pl</b>
    </InterestedText>
    <InterestedSection>
      <InterestedHeader>
        <InterestedHeaderAnswer>
          Please contact us if you have any questions
        </InterestedHeaderAnswer>
      </InterestedHeader>
      <ButtonLink to="/contact">
        <span>Contact Us!</span>
      </ButtonLink>
    </InterestedSection>
  </Section>
)

export default ContactUsLink
